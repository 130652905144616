import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    windowWidth: null,
    loading: true,
    AllBooks: [],
  },
  getters: {
    windowWidth: (state) => state.windowWidth,
    AllBooks: (state) => state.AllBooks,
    loading: (state) => state.loading,
  },
  mutations: {
    setWindowWidth(state, newWidth) {
      state.windowWidth = newWidth;
    },
    setAllBooks(state, data) {
      state.AllBooks = data;
    },
    setLoading: (state, data) => (state.loading = data),
  },
  actions: {
    async getAllBooks({ commit }, payload) {
      try {
        commit("setLoading", true);
        await axios
          .get(`http://api.navoiy-works.tsuull.uz/api/pr/${payload}`)
          .then((res) => {
            if (!res.error && res.data) {
              commit("setAllBooks", res.data);
            }
          })
          .catch((error) => {
            commit("setLoading", false);
            console.log("Error" + ": " + error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      } catch (e) {
        commit("setLoading", false);
        console.log(e);
      }
    },
  },
  modules: {},
});
